<template>
  <div>
    <el-table
      class="
        table-normal
        manage
        animate__animated animate__fadeIn animate__slow
      "
      :class="typeTable == 'keep' ? 'keep-text' : ''"
      :data="data"
      style="width: 100%"
      v-loading="loading"
      element-loading-text="กำลังโหลดข้อมูล..."
      element-loading-spinner="el-icon-loading"
    >
      <template slot="empty">
        <p class="font-30 font-weight-500">ไม่มีข้อมูล</p>
      </template>
      <el-table-column
        label="ขนาดธุรกิจ"
        min-width="150px"
        v-if="page == 'export-insurance' || page == 'credit'"
      >
        <template slot-scope="scope">
          <div
            v-if="page == 'export-insurance'"
            class="text-center"
            :class="
              checkDateExp(scope.row.insuranceEnd) == true
                ? 'color-35-disabled'
                : ''
            "
          >
            <el-tooltip
              v-if="
                scope.row.sendToCH == true &&
                checkDateExp(scope.row.insuranceEnd) == false
              "
              class="item font-22"
              effect="dark"
              content="ถูกนำเสนอประกันการส่งออก ไปยังระบบ Channel Integration แล้ว"
              placement="bottom-start"
            >
              <span>
                <span v-if="scope.row.insuranceSizeS">SIZE S </span>
                <span
                  v-if="
                    (scope.row.insuranceSizeS && scope.row.insuranceSizeM) ||
                    (scope.row.insuranceSizeS && scope.row.insuranceSizeL)
                  "
                  >,
                </span>
                <span v-if="scope.row.insuranceSizeM">SIZE M </span>
                <span
                  v-if="scope.row.insuranceSizeM && scope.row.insuranceSizeL"
                  >,
                </span>
                <span v-if="scope.row.insuranceSizeL">SIZE L </span>
              </span>
            </el-tooltip>
            <div v-else>
              <span v-if="scope.row.insuranceSizeS">SIZE S </span>
              <span
                v-if="
                  (scope.row.insuranceSizeS && scope.row.insuranceSizeM) ||
                  (scope.row.insuranceSizeS && scope.row.insuranceSizeL)
                "
                >,
              </span>
              <span v-if="scope.row.insuranceSizeM">SIZE M </span>
              <span v-if="scope.row.insuranceSizeM && scope.row.insuranceSizeL"
                >,
              </span>
              <span v-if="scope.row.insuranceSizeL">SIZE L </span>
            </div>
          </div>
          <p
            class="text-center mg-0"
            :class="
              checkDateExp(scope.row.creditEnd) == true
                ? 'color-35-disabled'
                : ''
            "
            v-else
          >
            <span v-if="scope.row.creditSizeS">SIZE S </span>
            <span
              v-if="
                (scope.row.creditSizeS && scope.row.creditSizeM) ||
                (scope.row.creditSizeS && scope.row.creditSizeL)
              "
              >,
            </span>
            <span v-if="scope.row.creditSizeM">SIZE M </span>
            <span v-if="scope.row.creditSizeM && scope.row.creditSizeL"
              >,
            </span>
            <span v-if="scope.row.creditSizeL">SIZE L </span>
          </p>
        </template>
      </el-table-column>
      <el-table-column label="เกรด" min-width="150px" v-if="page == 'credit'">
        <template slot-scope="scope">
          <span
            v-if="scope.row.grades.length > 0"
            :class="
              checkDateExp(scope.row.creditEnd) == true
                ? 'color-35-disabled'
                : ''
            "
          >
            <span v-for="(grade, index) in scope.row.grades" :key="index"
              >{{ grade.gradeName
              }}{{ index == scope.row.grades.length - 1 ? "" : ", " }}</span
            >
          </span>
          <span
            class="color-red-soft"
            :class="
              checkDateExp(scope.row.creditEnd) == true
                ? 'color-35-disabled'
                : ''
            "
            v-else
            >กรุณาแก้ไขเกรด!</span
          >
        </template>
      </el-table-column>
      <el-table-column
        label="ประเภทของสินเชื่อ"
        min-width="150px"
        v-if="page == 'credit'"
      >
        <template slot-scope="scope">
          <div
            :class="
              checkDateExp(scope.row.creditEnd) == true
                ? 'color-35-disabled'
                : ''
            "
          >
            <el-tooltip
              v-if="
                scope.row.sendToCH == true &&
                checkDateExp(scope.row.creditEnd) == false
              "
              class="item font-22"
              effect="dark"
              content="ถูกนำเสนอสินเชื่อ ไปยังระบบ Channel Integration แล้ว"
              placement="bottom-start"
            >
              <span>
                <span
                  v-if="
                    scope.row.interestType == 1 || scope.row.interestType == 3
                  "
                  >Working Capital</span
                >
                <span
                  v-else-if="
                    scope.row.interestType == 2 || scope.row.interestType == 4
                  "
                  >Loan (สินเชื่อระยะกลาง-ยาว)</span
                >
              </span>
            </el-tooltip>
            <div v-else>
              <span
                v-if="
                  scope.row.interestType == 1 || scope.row.interestType == 3
                "
                >Working Capital</span
              >
              <span
                v-else-if="
                  scope.row.interestType == 2 || scope.row.interestType == 4
                "
                >Loan (สินเชื่อระยะกลาง-ยาว)</span
              >
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="Product Code"
        min-width="120px"
        v-if="page == 'credit'"
      >
        <template slot-scope="scope">
          <span
            :class="
              checkDateExp(scope.row.creditEnd) == true
                ? 'color-35-disabled'
                : ''
            "
            v-if="
              scope.row.productProgramName != null &&
              scope.row.productProgramName != ''
            "
            >{{ scope.row.productProgramName }}</span
          >
          <span v-else>-</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        label="Major-Minor"
        min-width="120px"
        v-if="page == 'credit'"
      >
        <template slot-scope="scope">
          <span
            :class="
              checkDateExp(scope.row.creditEnd) == true
                ? 'color-35-disabled'
                : ''
            "
            v-if="scope.row.majorMinor != null && scope.row.majorMinor != ''"
            >{{ scope.row.majorMinor }}</span
          >
          <span v-else>-</span>
        </template>
      </el-table-column> -->
      <el-table-column
        label="Product Name"
        min-width="150px"
        v-if="page == 'credit'"
      >
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="scope.row.creditName"
            placement="bottom"
          >
            <span
              class="name-in-table"
              :class="
                checkDateExp(scope.row.creditEnd) == true
                  ? 'color-35-disabled'
                  : ''
              "
              >{{ scope.row.creditName }}</span
            >
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        label="วงเงินค้าขายต่อ Shipment"
        min-width="120px"
        v-if="page == 'export-insurance'"
      >
        <template slot-scope="scope">
          <div
            :class="
              checkDateExp(scope.row.insuranceEnd) == true
                ? 'color-35-disabled'
                : ''
            "
          >
            <div v-if="scope.row.shipment.length > 0">
              <span
                v-for="(shipment, indexS) in scope.row.shipment"
                :key="indexS"
              >
                <span v-if="shipment == 1">น้อยกว่า 0.5 ล้านบาท</span>
                <span v-else-if="shipment == 2">0.5 - 1 ล้านบาท</span>
                <span v-else-if="shipment == 3">1 ล้านบาทขึ้นไป</span>
                {{ indexS != scope.row.shipment.length - 1 ? ", " : "" }}
              </span>
            </div>
            <span v-else>-</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="มูลค่าการส่งออก"
        min-width="130px"
        v-if="page == 'export-insurance'"
      >
        <template slot-scope="scope">
          <div
            :class="
              checkDateExp(scope.row.insuranceEnd) == true
                ? 'color-35-disabled'
                : ''
            "
          >
            <div v-if="scope.row.exportValue.length > 0">
              <span
                v-for="(exportValue, indexE) in scope.row.exportValue"
                :key="indexE"
              >
                <span v-if="exportValue == 1">ไม่เกิน 100 ล้านบาท</span>
                <span v-else-if="exportValue == 2">101-200 ล้านบาท</span>
                <span v-else-if="exportValue == 3">201-300 ล้านบาท</span>
                <span v-else-if="exportValue == 4">301-400 ล้านบาท</span>
                <span v-else-if="exportValue == 5">401-500 ล้านบาท</span>
                <span v-else-if="exportValue == 6">มากกว่า 500 ล้านบาท</span>
                {{ indexE != scope.row.exportValue.length - 1 ? ", " : "" }}
              </span>
            </div>
            <span v-else>-</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        label="เทอมการชำระเงิน"
        min-width="120px"
        v-if="page == 'export-insurance'"
      >
        <template slot-scope="scope">
          <div
            :class="
              checkDateExp(scope.row.insuranceEnd) == true
                ? 'color-35-disabled'
                : ''
            "
          >
            <div v-if="scope.row.term.length > 0">
              <span v-for="(term, indexT) in scope.row.term" :key="indexT">
                <span v-if="term == 90">ไม่เกิน 90 วัน</span>
                <span v-else-if="term == 120">ไม่เกิน 120 วัน </span>
                <span v-else-if="term == 180">ไม่เกิน 180 วัน </span>
                {{ indexT != scope.row.term.length - 1 ? ", " : "" }}
              </span>
            </div>
            <span v-else>-</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="Insurance Product ID"
        min-width="150px"
        v-if="page == 'export-insurance'"
      >
        <template slot-scope="scope">
          <span
            :class="
              checkDateExp(scope.row.insuranceEnd) == true
                ? 'color-35-disabled'
                : ''
            "
            >{{ scope.row.insuranceProductId }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        label="ชื่อประกันการส่งออก"
        min-width="150px"
        v-if="page == 'export-insurance'"
      >
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="scope.row.insuranceName"
            placement="bottom"
          >
            <span
              class="name-in-table"
              :class="
                checkDateExp(scope.row.insuranceEnd) == true
                  ? 'color-35-disabled'
                  : ''
              "
              >{{ scope.row.insuranceName }}</span
            >
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        label="ชื่อกิจกรรม/โครงการ"
        min-width="225px"
        v-if="page == 'project'"
      >
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="scope.row.projectName"
            placement="bottom"
          >
            <span
              class="name-in-table"
              :class="
                checkDateExp(scope.row.registerEnd) == true
                  ? 'color-35-disabled'
                  : ''
              "
              >{{ scope.row.projectName }}</span
            >
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        label="ลักษณะผู้ประกอบการ"
        width="150px"
        v-if="page == 'project'"
      >
        <template slot-scope="scope">
          <div
            :class="
              checkDateExp(scope.row.registerEnd) == true
                ? 'color-35-disabled'
                : ''
            "
          >
            <span v-if="scope.row.operateType == 1">บุคคลธรรมดา</span>
            <span v-else-if="scope.row.operateType == 2">นิติบุคคล</span>
          </div>
        </template>
      </el-table-column>

      <!-- <el-table-column
        label="เงื่อนไขกิจกรรม/โครงการ"
        min-width="160px"
        v-if="page == 'project' && loadTERAK"
      >
        <template slot-scope="scope">
          <div
            class="d-grid"
            :class="
              checkDateExp(scope.row.registerEnd) == true
                ? 'color-35-disabled'
                : ''
            "
          >
            <span v-if="scope.row.businessSizeStatus == true">
              ขนาดธุรกิจ
            </span>
            <span v-if="scope.row.isGrade == true"> เกรด </span>
            <span v-if="scope.row.operatorStatus == true">
              ประเภทผู้ประกอบการ
            </span>
            <span v-if="scope.row.industryStatus == true">
              ประเภทอุตสาหกรรม
            </span>
            <el-tooltip
              class="item"
              effect="dark"
              placement="bottom"
              v-else-if="scope.row.isRateReady || scope.row.isFactorRateReady"
            >
              <div slot="content">
                <span v-if="scope.row.readiness.length > 0">
                  ด้านประเมินความพร้อม
                  <br />
                  <span
                    v-html="returnName(scope.row.readiness, 'readiness')"
                  ></span>
                </span>
                <span v-if="scope.row.factor.length > 0">
                  <br v-if="scope.row.readiness.length > 0" />
                  ปัจจัยประเมินความพร้อม
                  <br />
                  <span v-html="returnName(scope.row.factor, 'factor')"></span>
                </span>
              </div>
              <p class="mg-0 d-grid">
                <span v-if="scope.row.isRateReady">ด้านประเมินความพร้อม </span>
                <span v-if="scope.row.isFactorRateReady"
                  >ปัจจัยประเมินความพร้อม
                </span>
              </p>
            </el-tooltip>
            <span v-if="scope.row.isIncome == true">
              รายได้จากการดำเนินงาน (บาท)
            </span>
            <span v-if="scope.row.shipmentStatus == true">
              วงเงินค้าขายต่อ Shipment
            </span>
            <span v-if="scope.row.exportValueStatus == true">
              มูลค่าการส่งออก
            </span>
            <span v-if="scope.row.paymentTermStatus == true">
              เทอมการชำระเงิน
            </span>
            <span v-if="scope.row.projectTerakStatus == true">
              โครงการจากระบบ TERAK
            </span>
            <span
              v-if="
                scope.row.businessSizeStatus == false &&
                scope.row.isGrade == false&&
                scope.row.operatorStatus == false&&
                scope.row.industryStatus == false&&
                scope.row.isRateReady == false&&
                scope.row.isFactorRateReady == false&&
                scope.row.isIncome == false&&
                scope.row.shipmentStatus == false&&
                scope.row.exportValueStatus == false&&
                scope.row.paymentTermStatus == false&&
                scope.row.projectTerakStatus == false
              "
              >-</span
            >
          </div>
        </template>
      </el-table-column> -->
      <el-table-column label="เกรด" min-width="200px" v-if="page == 'project'">
        <template slot-scope="scope">
          <p
            class="mg-0"
            v-if="scope.row.grade.length > 0"
            :class="
              checkDateExp(scope.row.registerEnd) == true
                ? 'color-35-disabled'
                : ''
            "
          >
            <span
              v-for="(grade, indexGrade) in scope.row.grade"
              :key="indexGrade"
            >
              {{ grade.gradeName }}
              <span v-if="indexGrade + 1 != scope.row.grade.length"> , </span>
            </span>
          </p>
          <span
            v-else
            :class="
              checkDateExp(scope.row.registerEnd) == true
                ? 'color-35-disabled'
                : ''
            "
            >-</span
          >
        </template>
      </el-table-column>
      <!-- <el-table-column
        label="รายได้จากการดำเนินการ"
        min-width="150px"
        v-if="page == 'project'"
      >
        <template slot-scope="scope">
          <div
            v-if="
              scope.row.incomeMin != 0 &&
              scope.row.incomeMax != 0 &&
              scope.row.incomeMin != null &&
              scope.row.incomeMax != null
            "
            :class="
              checkDateExp(scope.row.registerEnd) == true
                ? 'color-35-disabled'
                : ''
            "
          >
            {{ scope.row.incomeMin | comma }} ถึง
            {{ scope.row.incomeMax | comma }}
          
          </div>
          <span
            v-else
            :class="
              checkDateExp(scope.row.registerEnd) == true
                ? 'color-35-disabled'
                : ''
            "
            >-</span
          >
        </template>
      </el-table-column> -->
      <!-- <el-table-column
        label="สมัครโครงการ"
        min-width="120px"
        v-if="page == 'project'"
      >
        <template slot-scope="scope">
          <span
            :class="
              checkDateExp(scope.row.registerEnd) == true
                ? 'color-35-disabled'
                : ''
            "
            v-if="scope.row.registerType == 1"
            >โครงการของหน่วยงาน EXAC</span
          >
          <el-tooltip
            class="item"
            effect="dark"
            :content="
              `ลิงก์โครงการ: ` + returnRegisterUrl(scope.row.registerUrl)
            "
            placement="bottom"
            v-else-if="scope.row.registerType == 2"
          >
            <span
              :class="
                checkDateExp(scope.row.registerEnd) == true
                  ? 'color-35-disabled'
                  : ''
              "
              >โครงการของหน่วยงานพันธมิตร</span
            >
          </el-tooltip>
        </template>
      </el-table-column> -->
      <el-table-column
        label="วันที่จัดกิจกรรม/โครงการ"
        min-width="120px"
        v-if="page == 'project'"
      >
        <template slot-scope="scope">
          <span
            :class="
              checkDateExp(scope.row.registerEnd) == true
                ? 'color-35-disabled'
                : ''
            "
          >
            {{ scope.row.eventDateEnd | formatDateTH }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        :label="
          page == 'project' ? 'วันที่เปิด-ปิดรับสมัคร' : 'ระยะเวลาอนุมัติวงเงิน'
        "
        min-width="150px"
      >
        <template slot-scope="scope">
          <div v-if="page == 'credit'">
            <span
              v-if="checkDateExp(scope.row.creditEnd) == true"
              class="color-red-soft"
            >
              สิ้นสุดเวลาสินเชื่อ เมื่อ {{ scope.row.creditEnd | formatDateTH }}
            </span>
            <span v-else
              >{{ scope.row.creditStart | formatDateTH }} -
              {{ scope.row.creditEnd | formatDateTH }}</span
            >
          </div>
          <div v-else-if="page == 'export-insurance'">
            <span
              v-if="checkDateExp(scope.row.insuranceEnd) == true"
              class="color-red-soft"
            >
              สิ้นสุดเวลาประกันการส่งออก เมื่อ
              {{ scope.row.insuranceEnd | formatDateTH }}
            </span>
            <span v-else
              >{{ scope.row.insuranceStart | formatDateTH }} -
              {{ scope.row.insuranceEnd | formatDateTH }}</span
            >
          </div>
          <div v-else-if="page == 'project'">
            <span
              v-if="checkDateExp(scope.row.registerEnd) == true"
              class="color-red-soft"
            >
              สิ้นสุดเวลาโครงการ เมื่อ
              {{ scope.row.registerEnd | formatDateTH }}
            </span>
            <span v-else
              >{{ scope.row.registerStart | formatDateTH }} -
              {{ scope.row.registerEnd | formatDateTH }}</span
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column label="" width="200px">
        <template slot-scope="scope">
          <div class="is-flex ai-center js-end">
            <!-- typeTable == 'keep' -->

            <el-button
              v-if="typeTable != 'keep'"
              class="btn-blue-soft"
              @click="goToLink(`/manage/${page}/detail/${scope.row._id}`)"
            >
              <i class="fas fa-eye"></i>
            </el-button>
            <el-button
              v-if="permissionMore == true"
              class="btn-blue-dark"
              @click="goToLink(`/manage/${page}/edit/${scope.row._id}`)"
            >
              <i class="fas fa-edit"></i>
            </el-button>
            <el-button
              v-if="permissionMore == true"
              class="btn-yellow"
              @click="copyItem(scope.row._id)"
              ><i class="fas fa-copy"></i>
            </el-button>
            <!-- <div v-if="checkKeepByRole" class="mg-x-6"> -->

            <el-tooltip
              v-if="typeTable != 'keep' && checkKeepByRole"
              class="item"
              effect="dark"
              :content="
                page == 'credit'
                  ? 'จัดเก็บสินเชื่อ'
                  : page == 'export-insurance'
                  ? 'จัดเก็บประกันการส่งออก'
                  : page == 'project'
                  ? 'จัดเก็บโครงการ'
                  : ''
              "
              placement="bottom"
            >
              <el-button
                class="btn-delete table"
                @click="handleKepItem(scope.row._id)"
              >
                <i class="fas fa-archive"></i>
              </el-button>
            </el-tooltip>
            <el-button
              v-else-if="typeTable == 'keep'"
              class="btn-delete table"
              @click="handleKeepItemCancle(scope.row._id)"
            >
              <i class="fas fa-trash-restore"></i>
            </el-button>
            <!-- </div> -->
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: [
    "data",
    "page",
    "loading",
    "typeTable",
    "checkKeepByRole",
    "loadTERAK",
    "optionsCates",
    "optionsQuestion",
    "permissionMore",
    "typeData",
  ],
  data() {
    return {};
  },
  methods: {
    checkDateExp(val) {
      let now = new Date();
      let dateNow = moment(now).format("YYYY-MM-DD");
      let dateEnd = moment(val).format("YYYY-MM-DD");
      if (dateNow > dateEnd) {
        return true;
      } else if (dateNow == dateEnd) {
        return false;
      } else {
        return false;
      }
    },
    goToLink(data) {
      this.$router.push(data);
    },
    returnName(data, type) {
      if (this.loadTERAK) {
        let result = "";
        if (type == "readiness") {
          for (let i = 0; i < this.optionsCates.length; i++) {
            for (let y = 0; y < data.length; y++) {
              if (this.optionsCates[i].id == data[y].value) {
                result += this.optionsQuestion[i].name;
                result +=
                  " ระดับ " +
                  data[y].levelMin +
                  " - ระดับ " +
                  data[y].levelMax +
                  "<br>";
              }
            }
          }
        }
        if (type == "factor") {
          for (let i = 0; i < this.optionsQuestion.length; i++) {
            for (let y = 0; y < data.length; y++) {
              if (this.optionsQuestion[i].id == data[y].value) {
                result += this.optionsQuestion[i].name;
                if (typeof data[y].score != "undefined") {
                  data[y].score.forEach((score, index) => {
                    result += " ระดับ " + score;
                    index != data[y].score.length - 1 ? (result += ", ") : "";
                  });
                }
                result += "<br>";
              }
            }
          }
        }
        return result;
      }
    },
    handleKepItem(id) {
      this.$emit("keepTable", id, this.typeData);
    },
    handleKeepItemCancle(id) {
      this.$emit("cancleKeepTable", id,  this.typeData);
    },
    copyItem(id) {
      this.$emit("copyItem", id, this.typeData,this.typeTable);
    },
    returnRegisterUrl(url) {
      let result = "";
      result = url;
      if (result.length > 50) {
        result = result.substring(0, 49) + "...";
      }
      return result;
    },
  },
};
</script>